.wrapper {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 95px;
  margin-top: 30px;
}

.title {
  margin: 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: var(--typo-main);
}

.number {
  display: block;
  margin-top: 4px;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: -0.408px;
  color: var(--typo-secondary);
}

.text {
  margin: 24px 0 0;
  font-weight: 400;
  font-size: 21px;
  line-height: 143%;
  color: var(--typo-main);
}

.messageWrapper {
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  align-items: end;
  gap: 12px;
}

.messageWrapperBottom {
  display: none;
}

.imagesWrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.image {
  width: 100%;
  height: 127px;
  border-radius: 16px;
}

.extendedImage {
  display: none;
}

.overlayWrapper {
  position: relative;
  height: 127px;
  border-radius: 16px;
  overflow: hidden;
}

.overlay {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  color: white;
}

@media (max-width: 1199px) {
  .wrapper {
    grid-template-columns: 2.9fr 1fr;
    gap: 85px;
  }
  .imagesWrapper {
    grid-template-columns: 1fr;
    gap: 24px;
    height: fit-content;
  }
}

@media (max-width: 959px) {
  .wrapper {
    grid-template-columns: 1fr;
    gap: 16px;
  }
  .imagesWrapper {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    height: fit-content;
  }
  .extendedImage {
    display: block;
  }
  .messageWrapper {
    display: none;
  }
  .messageWrapperBottom {
    display: flex;
    margin-top: 16px;
  }
}

@media (max-width: 767px) {
  .extendedImage {
    display: none;
  }
  .imagesWrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}