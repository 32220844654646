.wrapper {
  border-radius: 8px;
  box-shadow: 0 1px 8px rgba(21, 38, 71, 0.17);
  overflow: hidden;
  cursor: pointer;
}

.addressItem {
  display: flex;
  justify-content: space-between;
}

.addressWrapper {
  display: flex;
  flex-direction: column;
  padding: 17px 16px;
}

.extended {
  border-left: 4px solid var(--green);
  padding-left: 12px;
}

.moreIcon {
  transition: transform .3s linear;
}

.reverse {
  transform: rotate(180deg);
  fill: var(--typo-main);
}

.address {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
}

.extendedWrapper {
  display: flex;
  margin-top: 26px;
  gap: 54px;
}

.table {
  display: flex;
  flex-direction: column;
  width: 407px;
}

.tableItem {
  padding: 9px 16px;
  display: grid;
  grid-template-columns: 1.7fr 1fr;
  border-bottom: 1px solid var(--border-main);
  align-items: center;
}

.tableItem:last-of-type {
  border: none;
}

.meter, .numberWrapper {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
}

.numberWrapper {
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: var(--text-secondary);
}


.areaWrapper {
  margin-top: 10px;
}

.areaCaption, .area {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.area {
  color: var(--text-secondary);
}

.deleteButton {
  display: flex;
  align-items: center;
  gap: 13px;
  padding: 6px 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 0.4px;
  color: var(--red);
}

.redCross {
  width: 14px;
  height: 14px;
  fill: var(--red);
}

@media (max-width: 767px) {
  .table {
    width: 354px;
  }
  .address {
    max-width: 220px;
  }
}