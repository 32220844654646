.error {
  margin-left: 10px;
  color: white;
  font-size: 14px;
  align-items: center;
}



.property {
  font-weight: 700;
  margin-right: 5px;
}