.meter {
  padding: 12px 12px 0;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
  min-height: 118px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.408px;
}

.value {
  margin: 8px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.408px;
  color: var(--green);
}

.bgIconWrapper {
  position: absolute;
  bottom: 0;
  max-width: 100%;
  align-self: center;
  border-radius: 0 0 16px 16px;
  overflow: hidden;
  display: flex;
}
