.ticket {
  display: grid;
  grid-template-columns: 1fr 1.1fr 1.1fr 1.5fr 1fr 1.5fr 2.5fr;
  gap: 10px;
  padding: 17px 16px;
  border-radius: 8px;
  transition: box-shadow .5s;
  align-items: flex-start;
}

.ticket:hover {
  box-shadow: 0 1px 8px rgba(21, 38, 71, 0.17);
  cursor: pointer;
}

.number {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.number,
.date,
.priority,
.status,
.appeal,
.address {
  line-height: 1.5;
}

.date {
  color: var(--gray);
  max-width: 90px;
}

.status {
  display: flex;
  align-items: center;
}

.statusName {
  width: fit-content;
  padding: 5px 10px;
  border-radius: 8px;
}

.statusCircle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
}

.appeal {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.blockWrapper {
  grid-row: 2 / 3;
  grid-column: 1 / 5;
}


@media (max-width: 767px) {
  .ticket {
    grid-template-columns: 1fr 1fr;
    box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
    border-radius: 16px;
    padding: 24px;
    row-gap: 8px;
  }
  .status {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
    justify-self: end;
  }
  .priority {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    justify-self: end;
  }
  .appeal {
    grid-column: 1 / 3;
    grid-row: 2 / 3;
    font-weight: 500;
  }

  .address {
    grid-column: 1 / 2;
  }

  .customer {
    grid-column: 1 / 3;
    grid-row: 5 / 6;
  }

  .justifyEnd {
    justify-self: end;
  }

  .number {
    color: var(--text-secondary);
  }
  .number::before {
    content: "№ ";
  }
}
