.wrapper {
  padding: 24px;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.headingWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.408px;
}

.number {
  font-size: 14px;
  line-height: 143%;
  letter-spacing: -0.24px;
  color: var(--text-secondary);
}

.iconWrapper {
  margin-right: 21px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .iconWrapper {
    margin: 0;
  }
}