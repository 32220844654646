.wrapper {
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
  height: fit-content;
  overflow: hidden;
}

.borderWrapper {
  border-left: 6px solid var(--green);
  padding: 20px;
}

.heading {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.text {
  margin-top: 4px;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: var(--text-secondary);
}

.button {
  display: flex;
  align-items: center;
  padding: 6px 13px;
  gap: 13px;
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 0.4px;
  color: var(--green);
}

@media (max-width: 959px) {
  .wrapper {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
}

@media (max-width: 767px) {
  .wrapper {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  .borderWrapper {
    padding: 12px 10px;
  }
}