.wrapper {
  margin-top: 87px;
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
}

.title {
  margin: 16px 0 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 83%;

}

.subtitle {
  margin: 16px 0 0;
  font-size: 16px;
  line-height: 125%;
}

.button {
  margin-top: 24px;
  padding: 8px 24px;
}