.wrapper {
  width: 331px;
  top: 68px;
  right: 35px;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
}

.homeItem {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-bottom: 1px solid var(--border-main);
  cursor: pointer;
}

.homeItem:hover {
  background: rgba(0, 0, 0, 0.04);
}

.iconWrapper {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active {
  background-color: var(--green);
  border-radius: 50%;
}

.active svg {
  fill: white;
}

.address {
  margin: 0;
}

.newHome {
  font-size: 16px;
  line-height: 150%;
  color: var(--green);
  padding: 12px 25px;
  display: flex;
  align-items: center;
  gap: 17px;
}

@media (max-width: 767px) {
  .wrapper {
    left: 20px;
    right: 20px;
    width: auto;
  }
}