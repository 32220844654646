.header {
    display: flex;
    align-items: center;
    padding: 0 40px;
    justify-content: space-between;
    min-height: 60px;
    border-bottom: 1px solid var(--border-main);
    position: relative;
    z-index: 1201;
    background-color: white;
}

.burger {
    background-image: url("../../../assets/img/hamburger.svg");
    width: 12px;
    height: 10px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 38px;
    display: none;
}

.burgerOpened {
    background-image: url("../../../assets/img/modal-cross.svg");
    width: 14px;
    height: 14px;
}

.profileWrapper {
    display: flex;
    align-items: center;
    gap: 16px;
}

.buttonWrapper {
    display: flex;
    gap: 8px;
}

.button {
    width: 24px;
    height: 24px;
    padding: 3px 4px;
}

.ticketLink {
    margin-left: auto;
    margin-right: 10px;
}

.ticketButton {
    display: flex;
    align-items: center;
    padding: 12px 30px;
    border: none;
    background-color: var(--green);
    color: white;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
}

.ticketButton svg {
    margin-right: 13px;
}

.hidden {
    display: none;
}

.wrapper {
    display: flex;
    align-items: center;
}

.logo {
    margin-right: 32px;
    font-weight: 700;
}

.logo a {
    display: flex;
    align-items: center;
    gap: 5px;
}

.logo img {
    width: 30px;
}

.navList {
    display: flex;
    gap: 16px;
    color: var(--gray);
}


.activeLink {
    padding-bottom: 18px;
    border-bottom: 2px solid var(--green);
    color: #002033;
    font-weight: 500;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 12px;
}

.userInfo {
    display: flex;
    align-items: center;
}

.userName {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    margin-bottom: 4px;
    text-align: start;
}

.userAddress {
    font-size: 12px;
    line-height: 110%;
    color: var(--gray-light);
}

.menuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

@media (max-width: 1199px) {
    .navigation {
        display: none;
    }
    .burger {
        display: block;
    }
    .buttonWrapper {
        gap: 24px;
    }
    .profileWrapper {
        gap: 24px;
    }
}

@media (max-width: 767px) {
    .header {
        padding: 0 20px 0 35px;
    }
    .avatar {
        margin: 0;
    }
    .logo {
        display: none;
    }
    .buttonWrapper {
        gap: 12px;
    }
    .profileWrapper {
        gap: 16px;
    }
    .info {
        display: none;
    }
    .ticketButton {
        padding: 10px 10px;
    }
}
