.wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
}

.date {
  font-size: 14px;
  color: var(--gray);
}

.name {
  font-size: 16px;
  font-weight: 500;
}

.action {
  font-size: 14px;
}

.images {
  max-width: 50%;
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 5vw;
  gap: 5px;
}

.imageItem {
  border-radius: 10px;
}

.image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}
