.wrapper {
  width: fit-content;
  min-width: 100%;
}

.table {
  margin-top: 16px;
  padding: 12px;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
}

.headings {
  display: grid;
  border-bottom: 1px solid var(--border-main);
  padding: 0 16px;
  gap: 10px
}

.heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
}

