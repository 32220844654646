.previewList {
  display: flex;
  gap: 10px;
}

.previewWrapper {
  width: 100px;
  height: 100px;
  position: relative;
}

.preview {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.previewWrapper:hover .preview{
  -webkit-box-shadow: 0 0 30px 10px rgba(34, 60, 80, 0.5);
  -moz-box-shadow: 0 0 30px 10px rgba(34, 60, 80, 0.5);
  box-shadow: 0 0 20px 5px rgba(34, 60, 80, 0.4);
}

.pdfList {
  padding: 0;
  margin-top: 10px;
}

.pdfWrapper {
  margin-top: 10px;
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 5px;
}

.pdfImage {
  width: 24px;
  height: 24px;
}

.fileName {
  margin: 0 0 0 10px;
  font-size: 14px;
}

.deletePdfButton {
  width: 20px;
  height: 20px;
  background-color: gray;
  background-image: url("../../../../assets/img/cross-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  border-radius: 50%;
  padding:0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.uploadButtonWrapper {
  display: flex;
  align-items: center;
}

.filesCapture {
  font-size: 22px;
  margin-right: 20px;
}


.deleteButton {
  width: 20px;
  height: 20px;
  background-color: gray;
  background-image: url("../../../../assets/img/cross-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  padding:0;
  display: flex;
  align-items: center;
  justify-content: center;
}
