.tag {
  display: flex;
  gap: 6px;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  max-width: fit-content;
  color: white;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.green {
  background-color: var(--green);
}

.red {
  background-color: var(--red);
}