.wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px;
  box-shadow: 0 0 2px rgba(0, 32, 51, 0.08), 0 2px 8px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
}

.tagsWrapper {
  margin-top: 10px;
  display: flex;
  gap: 4px;
}

.metersDataEmpty {
  margin: 14px auto 0;
}

.textWrapper {
  margin-top: 32px;
  display: flex;
  gap: 17px;
}

.bellIconWrapper {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background-color: white;
  box-shadow: 0 0 2px rgba(0, 32, 51, 0.08), 0 2px 8px rgba(0, 32, 51, 0.16);
  border-radius: 8px;
}

.text {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.button {
  margin-top: auto;
}

@media (max-width: 767px) {
  .wrapper {
    padding: 16px;
  }
  .button {
    margin-top: 24px;
  }
}