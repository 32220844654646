.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.button {
  gap: 13px;
  padding: 12px 36px 12px 29px;
}

.table {
  margin-top: 16px;
  padding: 12px;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
}

.headings {
  display: grid;
  grid-template-columns: 1fr 1.1fr 1.1fr 1.5fr 1fr 1.5fr 2.5fr;
  gap: 10px;
  border-bottom: 1px solid var(--border-main);
  padding: 0 16px;
}

.heading {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: var(--gray);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 13px 0;
}

.headingAppeal {
  border-radius: 0 8px 0 0;
}

.tickets {
  border-bottom: 1px solid var(--border-main);
}

.plusIcon {
  fill: white;
}


@media (max-width: 767px) {
  .headings {
    display: none;
  }

  .tickets {
    border: none;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .table {
    box-shadow: none;
    border-radius: initial;
    padding: 0;
    margin-top: 24px;
  }
}

@media (max-width: 413px) {
  .buttonWrapper {
    flex-direction: column;
    align-items: start;
    gap: 16px;
  }
}
