.item {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 12px;
}

.topInfo {
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--border-main);
}

.title {
  font-weight: 500;
  font-size: 15px;
  line-height: 120%;
  color: var(--typo-main);
  max-width: 134px;
}

.text {
  margin-top: 8px;
  font-size: 10px;
  line-height: 130%;
  color: var(--typo-main);
}

.priceWrapper {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.priceCaption {
  font-size: 10px;
  line-height: 130%;
  color: var(--text-secondary);
}

.price {
  font-weight: 500;
  font-size: 15px;
  line-height: 87%;
  color: var(--typo-main);
}