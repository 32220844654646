.item {
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
  padding-left: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  overflow: hidden;
}



.textWrapper {
  flex-shrink: 0;
}

.title {
  margin-top: 12px;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
}

.text {
  margin-top: 8px;
  font-weight: 400;
  font-size: 10px;
  line-height: 130%;
  color: var(--typo-main);
  padding-bottom: 12px;
  max-width: 145px;
}

.image {
  object-fit: cover;
}

@media (max-width: 959px) {
  .item:last-of-type {
    grid-column: 1 / 3;
    width: fit-content;
    justify-items: center;
    justify-self: center;
    gap: 10px;
  }
}

@media (max-width: 959px) {
  .item:last-of-type {
    grid-column: initial;
    width: 100%;
  }
}