.navigation {
  background-color: white;
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 16px 0 0 36px;
  transform: translateX(-100%);
  transition: transform 0.25s linear;
}

.opened {
  transform: translateX(0);
}

.navItem {
  padding: 16px 16px 16px 0;
}

.childNavItem {
  padding: 10px 10px 10px 0;
  margin-left: 15px;
}

.childNavItem:first-of-type {
  margin-top: 10px;
}

.link {
  padding-left: 16px;
  font-size: 16px;
  line-height: 100%;
  color: var(--typo-main);
}

.activeLink {
  border-left: 2px solid var(--green);
  font-weight: 500;
}

.childLink {
  padding-left: 10px;
  font-size: 16px;
  line-height: 100%;
  color: var(--typo-main);
}

.activeChildLink {
  border-left: 2px solid var(--green);
  font-weight: 500;
}


@media (max-width: 800px) {
  .link {
    font-size: 22px;
  }
  .activeLink {
    border: none;
  }
}

@media (max-width: 767px) {
  .navigation {
    padding: 16px;
  }
}


