.title {
  margin: 26px 0 0;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  color: var(--typo-main);
}

.date {
  margin: 4px 0 0;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: -0.408px;
  color: var(--typo-secondary);
}

.button {
  padding: 10px 28px;
}

.gridWrapper {
  display: grid;
  grid-template-columns: 1.35fr 1fr;
  margin-top: 26px;
  gap: 24px;
  padding-bottom: 40px;
  position: relative;
}

.separator {
  position: absolute;
  left: -1000%;
  right: -1000%;
  bottom: 0;
  height: 1px;
  background-color: var(--border-main);
}

.newsWrapper {
}

.image {
  border-radius: 16px;
  object-fit: cover;
  width: 100%;
}

.textWrapper {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.text {
  margin: 0;
  font-size: 21px;
  line-height: 143%;
  color: var(--typo-main);
}

.share {
  padding: 16px 20px;
  background: #F9F9F9;
  border-radius: 16px;
  width: fit-content;
  height: fit-content;
}

.shareText {
  margin: 0 0 16px;
  font-size: 14px;
  line-height: 171%;
  color: #333333;
}

.otherNewsWrapper {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.otherNewsTitle {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  color: var(--text-main);
}

.newsList {
  margin-top: 16px;
  display: flex;
  gap: 24px;
}

.allNewsBottom {
  display: none;
}

@media (max-width: 959px) {
  .gridWrapper {
    grid-template-columns: 1.8fr 1fr;
  }
}

@media (max-width: 767px) {
  .gridWrapper {
    grid-template-columns: 1fr;
    padding-bottom: 32px;
  }
  .share {
    width: 100%;
    padding: 12px;
  }
  .button {
    width: 100%;
    font-size: 14px;
  }
  .newsList {
    flex-direction: column;
  }
  .image {
    min-height: 329px;
  }
}

@media (max-width: 413px) {
  .allNews {
    display: none;
  }
  .allNewsBottom {
    margin-top: 20px;
    display: block;
  }
}