.wrapper {
  margin: 22px auto 0;
  box-sizing: content-box;
  max-width: 1120px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0 0 16px rgba(0, 32, 51, 0.08), 4px 2px 24px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
}

.icon {
  background-image: url("../../../assets/img/login.svg");
  margin: 8px 0 8px 8px;
  width: 100%;
  height: 733px;
  background-size: cover;
  border-radius: 16px;
  background-repeat: no-repeat;
}

.inputWrapper {
  padding: 130px 40px 0;
  display: flex;
  flex-direction: column;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
}

.subtitle {
  margin: 20px 0 0;
  max-width: 304px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-secondary);
}

.label {
  margin: 24px 0 0 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: var(--text-secondary);
}

.caption {
  margin-top: 12px;
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: var(--text-secondary);
}

.link {
  color: var(--green);
}

.button {
  margin-top: 16px;
}

.supportPhoneWrapper {
  margin-top: auto;
  padding-bottom: 48px;
}

.supportPhoneCapture {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-secondary);
  margin-bottom: 8px;
}

.supportPhone {
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
}

.input::placeholder {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 2px;
  color: var(--text-secondary);
}

@media (max-width: 1199px) {
  .wrapper {
    max-width: 880px;
  }
  .icon {
    height: 476px;
  }
  .inputWrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
  .supportPhoneWrapper {
    display: none;
  }
}

@media (max-width: 959px) {
  .flexWrapper {
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
    max-width: 688px;
    margin: 0;
    min-height: 492px;
    grid-template-columns: 1fr 1.2fr;
  }
  .inputWrapper {
    padding: 100px 35px 0 25px;
  }
  .subtitle {
    font-size: 16px;
  }
  .label {
    margin-top: 16px;
  }
  .icon {
    object-position: left top;
  }
}

@media (max-width: 767px) {
  .flexWrapper {
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }
  .icon {
    margin: 0;
    border-radius: 0;
    background-image: url('../../../assets/img/login-mobile.svg');
    height: 268px;
    background-position: center;
  }
  .wrapper {
    max-width: 100%;
    min-height: 100vh;
    margin: 0;
    grid-template-columns: 1fr;
    grid-template-rows: 268px 1fr;
  }
  .inputWrapper {
    padding: 44px 16px 0;
  }
  .subtitle {
    font-size: 16px;
  }
  .label {
    margin-top: 12px;
  }
}
