.wrapper {
  font-size: 24px;
  padding-top: 100px;
  text-align: center;
}

.wrapper::after {
  content: '\2936';
  display: block;
  font-size: 64px;
  margin-top: 10px;
  color: var(--green);
}