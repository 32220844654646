.item {
  padding: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
}

.title {
  font-weight: 500;
  font-size: 16px;
  line-height: 125%;
}

.text {
  margin-top: 4px;
  font-size: 14px;
  line-height: 129%;
  color: var(--text-secondary);
}