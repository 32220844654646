html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--font-family);
    color: var(--text-main);
}

div[role=dialog] {
    z-index: 100000;
}

a {
    color: inherit;
    text-decoration: none;
    transition: opacity 0.5s;
}

p, h1, h2, h3, h4, h5, h6 {
    margin: 0;
}


a:hover {
    opacity: 0.7;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

button {
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    transition: opacity 0.5s;
}

button:hover {
    opacity: 0.7;
}

* {
    box-sizing: border-box;
}

.app {
    overflow: hidden;
    min-height: 100vh;
}

.content {
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: content-box;
    padding: 32px 40px 54px;
    display: flex;
    flex-direction: column;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form_ticket {
    padding: 50px;
}

:root {
    --gray: #66707A;
    --gray-light: #9CA5B8;
    --green: #4DAE67;
    --border-main: #CCD9E0;
    --red: #D32F2F;
    --dark-red: #923333;
    --text-main: #262A2E;
    --text-secondary: #66707A;
    --text-system: #9CA5B8;
    --typo-main: #152647;
    --typo-secondary: #99A2AD;

    --font-family: 'Rubik', sans-serif;
}

.swiper {
    width: 100%;
    overflow: visible !important;
}

.swiper-pagination {
    bottom: -35px!important;
}

.swiper-pagination-bullet-active {
    background-color: var(--typo-main)!important;
}

@media (max-width: 767px) {
    .content {
        padding: 32px 16px;
    }
}

