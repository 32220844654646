.wrapper {
    display: grid;
    grid-template-columns: 210px 1fr;
}

.main {
    padding: 32px 40px;
}

@media screen and (max-width: 800px) {
    .wrapper {
        grid-template-columns: auto;
    }
    .main {
        padding: 32px 12px;
        overflow: scroll;
    }
}
