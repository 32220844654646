.item {
  padding: 16px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.titleWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: var(--typo-main);
}

.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: var(--text-secondary);
}

.text {
  font-size: 16px;
  line-height: 138%;
}