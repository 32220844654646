
.ticketsList {
  margin-top: 16px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.moreButton {
  width: fit-content;
  margin: 16px auto 0;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 20px;
}

.moreButton svg {
  fill: var(--green);
}

.swiper {
  margin-top: 24px;
}

.categoriesTitle {
  margin-top: 40px;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
}

.popularList {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.otherList {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

@media (max-width: 959px) {
  .otherList {
    grid-template-columns: repeat(2, 1fr);
  }
  .popularList {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 16px 24px;

  }
}

@media (max-width: 767px) {
  .ticketsList {
    grid-template-columns: 1fr;
  }
  .otherList {
    grid-template-columns: 1fr;
  }
  .popularList {
    grid-template-columns: 1fr;
    justify-content: center;
    gap: 16px 24px;
  }
  .categoriesTitle {
    margin-top: 60px;
  }
  .moreButton {
    width: 100%;
  }
}