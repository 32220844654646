.wrapper {
  border-radius: 11px;
  padding: 2px 11px 2px 8px;
  background-color: var(--green);
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 9px;
  line-height: 100%;
  height: fit-content;
  width: fit-content;
  color: white;
}

.icon {
  width: 8px;
  height: 12px;
  fill: white;
}