.wrapper {
  top: 66px;
  right: 40px;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 16px rgba(17, 8, 8, 0.16);
  border-radius: 4px;
  background-color: white;
  width: 218px;
}

.link {
  display: flex;
  align-items: center;
  padding: 16px 20px;
  width: 100%;
}

.link:hover {
  background: rgba(0, 66, 105, 0.04);
}

.linkText {
  margin: 0 0 0 12px;
  font-size: 16px;
  line-height: 100%;
  color: var(--typo-main);
}

.arrow {
  margin-left: auto;
}

@media (max-width: 767px) {
  .wrapper {
    left: 20px;
    right: 20px;
    width: auto;
  }
}
