.input {
  border: 1px solid var(--border-main);
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  padding: 16px 12px;
  outline-color: var(--green);
}

.input::placeholder {
  font-weight: 400;
  letter-spacing: 0.15px;
  color: var(--text-secondary);
}

.withIcon {
  background-image: url('../../../../assets/img/header/home.svg');
  background-repeat: no-repeat;
  background-position: calc(100% - 16px);
}
