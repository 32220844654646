.wrapper {
  padding: 32px;
  box-shadow: 0 0 2px rgba(0, 32, 51, 0.08), 0 2px 8px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 21px;
  line-height: 20px;
}

.tagsWrapper {
  margin-top: 10px;
  display: flex;
  gap: 4px;
}

.categoriesWrapper {
  padding: 16px 0;
  border-bottom: 1px dashed var(--border-main);
}

.category {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  align-items: center;
  color: var(--text-secondary);
}

.category:first-of-type {
  margin: 0;
}

.totalWrapper {
  margin-top: 16px;
}

.total {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.totalCapture {
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
}

.totalValue {
  font-weight: 500;
  font-size: 18px;
  line-height: 110%;
}

.buttonsWrapper {
  margin-top: 41px;
  display: flex;
  gap: 11px;
}

.button {
  padding: 0 5px;
  width: 100%;
}

@media (max-width: 959px) {
  .buttonsWrapper {
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 16px;
  }
}