.wrapper {
  transition: 0.3s ease;
  position: relative;
  min-height: 100vh;
  max-width: 1115px;
  margin: 0 auto;
  box-sizing: content-box;
  padding: 32px 40px 54px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.status,
.priority{
  display: block;
  padding: 5px 10px;
  border-radius: 10px;
}

.actionsWrapper {
  display: flex;
  gap: 10px;
}

.blockWrapper {
  display: flex;
  gap: 10px;
  flex-direction: column;
  border-radius: 16px;
  padding: 15px;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
}

.blockLabel {
  font-size: 12px;
  color: var(--gray);
  width: fit-content;
  border-radius: 8px;
}

.statusLabel {
  margin-left: 5px;
}

.blockTitle {
  font-size: 18px;
  margin-left: 10px;
}

.infoWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d7d5d5;
}

.flexWrapper {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.images {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 20vw;
  gap: 10px;
}

.imageItem {
  border-radius: 10px;
}

.image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.button {
  margin-left: auto;
  margin-top: 10px;
}


.infoWrapper:last-of-type {
  border: none;
  padding-bottom: 0;
}

.buttonWrapper {
  margin-left: auto;
  display: flex;
  gap: 10px;
  align-items: center;
}

.categoryWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}