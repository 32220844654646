.content {
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: white;
}

.button {
  width: 200px;
  padding: 10px;
  margin-top: 20px;
  background-color: var(--red);
  color: white;
  border-radius: 8px;
}
