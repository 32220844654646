.item {
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}

.number,
.parentCategory,
.address,
.date,
.customer{
  font-size: 14px;
}

.category {
  font-size: 22px;
  font-weight: 600;
}

.priority,
.status {
  width: fit-content;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 14px;
}

.address,
.date,
.customer {
  display: flex;
  gap: 5px;
  align-items: center;
}
