.crumbsList {
  display: flex;
  gap: 15px;
}

.crumb {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: var(--text-secondary);
  white-space: nowrap;
}

.crumb::before {
  content: url("../../../assets/img/crumbIcon.svg");
  margin-right: 15px;
}

.crumb:first-of-type:before {
  content: '';
  margin: 0;
}

.currentCrumb {
  color: var(--text-main);
}
