.doc {
  display: flex;
  flex-direction: column;
  padding: 20px;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  transition: box-shadow .3s linear;
  border-radius: 10px;
  cursor: pointer;
}

.doc:hover {
  box-shadow: 0 0 20px rgba(0, 32, 51, 0.08), 0 10px 32px rgba(0, 32, 51, 0.16);
}

.header {
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid var(--border-main);
}

.top {
  display: flex;
  gap: 20px;
  align-items: center;
}

.label {
  color: var(--gray);
}

.number {
  font-size: 22px;
  font-weight: 600;
}


.text {
  padding-top: 10px;
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
}
