.cards {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content h3 {
  margin-top: 20px;
  font-weight: 500;
}
