.pages {
  display: flex;
  gap: 8px;
  padding-left: 19px;
  cursor: pointer;
  align-items: center;
  margin-top: 10px;
  justify-content: center;
}

.page {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.17px;
}

.selectedPage {
  background-color: var(--green);
  color: white;
  border-radius: 4px;
}

.prevButton, .nextButton {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nextIcon {
  fill: var(--text-main);
  transform: rotate(-90deg);
}

.prevIcon {
  fill: var(--text-main);
  transform: rotate(90deg);
}

.disabled svg {
  fill: #9CA5B8;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  .pages {
    display: none;
  }
}
