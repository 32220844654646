.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.headingWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.title {
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
}

.button {
  display: flex;
  align-items: center;
  padding: 12px 30px;
  border: none;
  background-color: var(--green);
  color: white;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  cursor: pointer;
}

.button svg {
  margin-right: 13px;
}

@media (max-width: 767px) {
  .title {
    font-size: 16px;
    line-height: 20px;
    max-width: 130px;
  }
}

@media (max-width: 500px) {
  .button {
    padding: 12px;
  }
}
