.errorsList {
  margin: 10px 0;
  background-color: var(--dark-red);
  width: fit-content;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: disc;
}

.wrapper {
  margin-left: 10px;
  color: white;
  font-size: 14px;
  align-items: center;
}

.property {
  font-weight: 700;
  margin-right: 5px;
}