.heading {
  font-size: 22px;
  font-weight: 500;

}

.commentWrapper {
  display: flex;
  flex-direction: column;
}

.commentCaption {
  font-size: 12px;
  color: var(--gray-light);
}


.headFiles {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.filesCaption {
  font-size: 18px;
  font-weight: 500;
}

.emptyFiles {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 8px;
  border: 1px dashed var(--green);
}

.pickButton {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: var(--green);
}
