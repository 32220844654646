.wrapper {
  padding: 24px;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  gap: 8px;
  position: relative;
}

.checked {
  border: 2px solid var(--green);
}

.date {
  margin: 0;
  color: var(--text-system);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.408px;
}

.heading {
  margin: 8px 0 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.text {
  margin: 8px 0 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.408px;
  color: var(--text-secondary);
}

.pinIconWrapper {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: var(--green);
  position: absolute;
  left: 24px;
  top: -12px;
}
