.wrapper {
  width: 350px;
  top: 66px;
  right: 40px;
  position: absolute;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 24px rgba(12, 16, 31, 0.13);
  border-radius: 16px;
  background-color: white;
  overflow: hidden;
}

.heading {
  padding: 18px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: var(--text-main);
  border-bottom: 1px solid var(--border-main);
}

.notification {
  padding: 16px 24px;
  cursor: pointer;
}

.notification:hover {
  background: rgba(0, 0, 0, 0.04);
}

.flexWrapper {
  display: flex;
  justify-content: space-between;
}

.title {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-main);
}

.date {
  margin: 0;
  font-size: 14px;
  line-height: 143%;
  color: var(--text-secondary);
}

.status {
  margin: 5px 0 0;
  font-size: 13px;
  line-height: 20px;
  color: var(--text-secondary);
}

@media (max-width: 767px) {
  .wrapper {
    left: 20px;
    right: 20px;
    width: auto;
  }
}