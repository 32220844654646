.metersListWrapper {
  overflow: scroll;
  padding: 32px 40px;
}

.metersList {
  max-width: 1115px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 262px);
  gap: 24px;
}

.receiptWrapper {
  padding: 0 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  max-width: 1115px;
  margin: 0 auto;
  box-sizing: content-box;
}

.newAccount {
  display: flex;
  box-shadow: 0 0 2px rgba(0, 32, 51, 0.08), 0 2px 8px rgba(0, 32, 51, 0.16);
  border-radius: 16px;
  padding: 17px 32px;
  justify-content: space-between;
}

.textWrapper {
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  padding: 15px 0;
}

.newAccountTitle {
  margin: 0;
  font-weight: 600;
  font-size: 22px;
  line-height: 20px;
}

.newAccountText {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.newAccountIcon {
  margin-right: 100px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input {
  width: 100%;
  outline-color: var(--green);
}

.input::placeholder {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
}

.inputCaption {
  margin-left: 12px;
  font-size: 12px;
  line-height: 167%;
  letter-spacing: 0.4px;
  color: var(--text-secondary);
}

@media (max-width: 767px) {
  .metersListWrapper {
    padding: 24px 16px;
  }
  .receiptWrapper {
    padding: 0 16px;
    grid-template-columns: 1fr;
  }
}