.input {
  width: 100%;
  padding: 8px 12px;
}

.prevReadings {
  margin-left: 12px;
  font-weight: 400;
  font-size: 12px;
  line-height: 167%;
  letter-spacing: 0.4px;
  color: var(--text-secondary);
}