.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.46px;
  border-radius: 4px;
}

.primary {
  background-color: var(--green);
  color: white;
}

.outlined {
  color: var(--green);
  border: 1px solid var(--green);
}

.small {
  padding: 8px 12px;
  font-size: 14px;
  line-height: 16px;
}