.wrapper {
  display: flex;
}

.avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
}

.textWrapper {
  padding: 16px 12px;
  box-shadow: 0 0 8px rgba(0, 32, 51, 0.08), 0 4px 16px rgba(0, 32, 51, 0.16);
  border-radius: 0 12px 12px 12px;

}

.text {
  margin: 0;
}

.author {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  line-height: 129%;
  letter-spacing: -0.408px;
  color: var(--typo-secondary);
}