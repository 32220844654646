.item {
  display: grid;
  grid-template-columns: 2fr auto;
  align-items: center;
  padding: 17px 16px;
  border-radius: 8px;
  transition: box-shadow .5s;
  gap: 10px;
}

.priority {
  width: fit-content;
  display: block;
  padding: 5px 10px;
  border-radius: 8px;
}

.item:hover {
  box-shadow: 0 1px 8px rgba(21, 38, 71, 0.17);
  cursor: pointer;
}


.buttonWrapper {
  display: flex;
  gap: 10px;
}
