.profileInfo {
  margin-top: 24px;
  display: grid;
  grid-template-columns: 1fr 1.35fr;
  gap: 24px;
}

.userInfo, .companyInfo {
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
}

.userInfo {
  padding: 24px 58px 24px 32px;
}

.nameWrapper {
  display: flex;
  align-items: center;
  gap: 19px;
}

.name {
  margin: 0;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: -0.408px;
}

.dataWrapper {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 67px;
  row-gap: 14px;
}

.data {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.dataCapture {
  display: flex;
  align-items: center;
  gap: 9px;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 114%;
  letter-spacing: -0.41px;
  color: var(--typo-secondary);
}

.dataValue {
  margin: 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  letter-spacing: -0.41px;
  color: var(--typo-main);
}

.companyInfo {
  padding: 24px 32px 26px;
}

.companyName {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
}

.greenAccent {
  color: var(--green);
}

.companyGrid {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px 40px;
}

.icon {
  width: 16px;
  height: 16px;
}

.companyData {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.addressesWrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.addressButton {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  gap: 13px;
  font-weight: 500;
  font-size: 14px;
  line-height: 171%;
  letter-spacing: 0.4px;
  color: var(--green);
}

.addressList {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

@media (max-width: 1199px) {
  .profileInfo {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 959px) {
  .profileInfo {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 767px) {
  .companyGrid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 413px) {
  .nameWrapper {
    flex-direction: column;
    align-items: start;
  }
}