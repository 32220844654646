.wrapper {
  padding: 0 40px;
  max-width: 1115px;
  margin: 32px auto 54px;
  box-sizing: content-box;
}

.topInfoWrapper {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.link, .linkBottom {
  display: flex;
  align-items: center;
  gap: 11px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: var(--green);
}

.linkBottom {
  display: none;
}

.newsList {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (max-width: 767px) {
  .wrapper {
    padding: 0 16px;
  }
  .link {
    display: none;
  }
  .linkBottom {
    width: fit-content;
    display: flex;
    margin: 26px auto 0;
  }

}