.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.preloader {
  width: 100px;
  height: 220px;
}

.preloader path {
  fill: var(--green);
}