.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
}

.accentGreen {
  color: var(--green);
}

.deadlineInfo {
  font-size: 16px;
  line-height: 150%;
  letter-spacing: -0.408px;
  color: var(--text-secondary);
}

.wrapper {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
}

.inputsWrapper {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex-grow: 1;
}

@media (max-width: 959px) {
  .wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767px) {
  .wrapper {
    grid-template-columns: 1fr;
  }
}